import { connect } from 'react-redux';
import { React, useState, useEffect } from 'react';
import { history, http, langs } from '../../../helpers';
import ActionTypes from '../../../store/action-types';
import Layout from '../../../layouts/layout'
import DashboardLayout from '../../../layouts/dashboard'
import { toast } from 'react-toastify';
 
const Home = ({ user, onUserLogout }) => {

	const [orders, setOrders] = useState({ data: [] });
	const [config, setConfig] = useState({});
	const [option, setOptions] = useState({ status_id: "", from: "", to: "", user_id: "", page: 1, operator_id: 0});

  const logOut = () => {
    onUserLogout();
    history.push('/login');
  }
  
  let options = "?a=1";
  if(window.location.pathname == "/orders"){
	  options = `?user_id=${user.id}`
  }

  const s = () => {
	if(window.location.pathname != "/orders"){
		http.get("/orders/create", {})
		.then(response => {
			if (response.data) {
				setConfig(response.data);
			}
		})
		.catch(err => {
			toast.error(err.message);
		})
	}
  
  }
  
  const get = (csv) => {
	if(csv == "csv"){
		return http
		.get(`/orders${options}&status_id=${option.status_id}&from=${option.from}&to=${option.to}&page=${option.page}&operator_id=${option.operator_id}&pathname=${window.location.pathname}&export=${csv}`, {
		  data: {

		  },
		  responseType: 'arraybuffer'
		})
		.then(response => {
            let blob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              }),
              url = window.URL.createObjectURL(blob);
            window.open(url);
			
 		})  		
	}

  
	setOrders({ data: [] })
    http.get(`/orders${options}&status_id=${option.status_id}&from=${option.from}&to=${option.to}&page=${option.page}&operator_id=${option.operator_id}&pathname=${window.location.pathname}&export=${csv}`, {})
      .then(response => {
        if (response.data) {
          console.log(response.data)
          setOrders(response.data);
        }
      })
      .catch(err => {
        toast.error(err.message);
      })
  }

	var fromTo = (to, from) => {
		var dateFuture = to;
		var dateNow = from;

		var seconds = Math.floor((dateFuture - (dateNow))/1000);
		var minutes = Math.floor(seconds/60);
		var hours = Math.floor(minutes/60);
		var days = Math.floor(hours/24);

		hours = hours-(days*24);
		minutes = minutes-(days*24*60)-(hours*60);
		seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
		
		
		let pm = "";
		if(days > 0) pm += `${days} ${langs.dayLabel},`;
		if(hours > 0) pm += `${hours} ${langs.hourLabel},`;
		if(minutes > 0) pm+= `${minutes} ${langs.minLabel},`;
		if(seconds > 0) pm+= `${seconds} ${langs.secLabel}`;
		return pm;
	}
	
	const calPrice = (to, from, price) => {
		var dateFuture = to;
		var dateNow = from;

		var seconds = Math.floor((dateFuture - (dateNow))/1000);
		var minutes = (seconds/60);
		var hours = (minutes/60);
		var days = (hours/24);
		
		return hours * price
		
	}
  
  useEffect(() => {
  
	document.querySelector('#from jb-date-input')?.addEventListener('change',(e)=>{
		var json = option;
		json.from = (e.target.value) 
		json.page = 1;
		setOptions(json);
		get();
	});

	document.querySelector('#to jb-date-input')?.addEventListener('change',(e)=>{
		var json = option;
		json.to = (e.target.value) 
		json.page = 1;		
		setOptions(json);
		get();
	});

		
    s();
    get();
  }, []);
  
  function boxTime(order){
	  var type = (order?.product?.price?.find(e => e.id == order.price_id))
	  
	  //created_at
	  var append = 0;
	  if(type == "day") append = 0;
	  if(type == "month") append = 30;
	  if(type == "week") append = 7;
	  
		var date = new Date(order.created_at);

		var first = date.toString().split(' GMT')[0];
		var second = (new Date(date.setDate(date.getDate() + append))).toString().split(' GMT')[0];;

	  
	  return { from: first, to: second };
  }
  

  return (
    <Layout dashboard={true} beforeMain={"g-sidenav-show  bg-gray-100"} >
      <DashboardLayout>


		{ orders?.data.length > 0 && user.role == "admin" && window.location.pathname == "/dashboard/reports" ? <>
		<div className="row">
		
			{ orders?.user ? 
				<>

				<div className="col-xl-12 col-sm-12 mb-xl-0">
					<a href={`/dashboard/users/${orders.user.id}`} className="card border shadow-xs mb-4">
						<div className="card-body text-start p-3 w-100">
							<div className="row">
								<div className="col-12">
									<div className="w-100">
										<p className="text-sm text-secondary mb-1">{orders.user.name}</p>
										<h4 className="mb-0 font-weight-bold">{orders.user.username}</h4>
									</div>
								</div>
							</div>
						</div>
					</a>
				</div>
				
				</>
			: <></>}
			
			<div className="col-xl-6 col-sm-6 mb-xl-0">
				<a href="#" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue}</p>
									<h4 className="mb-0 font-weight-bold">{Number(orders?.sum[0].income) ? parseFloat(orders?.sum[0].income).toFixed(3) : 0}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>

			
			<div className="col-xl-6 col-sm-6 mb-xl-0">
				<a href="/dashboard/orders" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.orders}</p>
									<h4 className="mb-0 font-weight-bold">{(orders?.total)}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.addad}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			
			
			

			
			
			

			
			
			

			
			
			

			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="#" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.month}) ({langs.admin})</p>
									<h4 className="mb-0 font-weight-bold">{Number(orders?.admin_month[0].income) ? parseFloat(orders?.admin_month[0].income).toFixed(3) : 0}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="#" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.month}) ({langs.provider})</p>
									<h4 className="mb-0 font-weight-bold">{Number(orders?.provider_month[0].income) ? parseFloat(orders?.provider_month[0].income).toFixed(3) : 0}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="#" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.week}) ({langs.admin})</p>
									<h4 className="mb-0 font-weight-bold">{Number(orders?.admin_week[0].income) ? parseFloat(orders?.admin_week[0].income).toFixed(3) : 0}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="#" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.week}) ({langs.provider})</p>
									<h4 className="mb-0 font-weight-bold">{Number(orders?.provider_week[0].income) ? parseFloat(orders?.provider_week[0].income).toFixed(3) : 0}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="#" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.day}) ({langs.admin})</p>
									<h4 className="mb-0 font-weight-bold">{Number(orders?.admin_day[0].income) ? parseFloat(orders?.admin_day[0].income).toFixed(3) : 0}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			<div className="col-xl-3 col-sm-3 mb-xl-0">
				<a href="#" className="card border shadow-xs mb-4">
					<div className="card-body text-start p-3 w-100">
						<div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
							<svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
								<path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z" />
								<path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clipRule="evenodd" />
							</svg>
						</div>
						<div className="row">
							<div className="col-12">
								<div className="w-100">
									<p className="text-sm text-secondary mb-1">{langs.revenue} ({langs.day}) ({langs.provider})</p>
									<h4 className="mb-0 font-weight-bold">{Number(orders?.provider_day[0].income) ? parseFloat(orders?.provider_day[0].income).toFixed(3) : 0}</h4>
									<div className="d-flex align-items-center"><span className="text-sm ms-1">{langs.symbol}</span></div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
			
			
			
			
			
			
			
			
			
			
			

		</div>
		</> : <></> }
		
        <div className="row">
          <div className="col-12">
            <div className="card border shadow-xs mb-4">
              <div className="card-header border-bottom pb-0">
                <div className="d-sm-flex align-items-center">
                  <div>
                    <h6 className="font-weight-semibold text-lg mb-0">{langs.orders}</h6>
                    <p className="text-sm">{langs.ordersNotic}</p>
                  </div>
					{ user.role == "admin" ? <>
                  <div className="ms-auto d-flex">

                    <a href='#' onClick={()=>{
						get("csv");
					}} type="button" className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2">
                      <span className="btn-inner--text">{langs.csv_export}</span>
                    </a>
                  </div>
					</> : null }
                </div>
              </div>
              <div className="card-body px-0 py-0">
			  { window.location.pathname == "/dashboard/reports" ? <>
              <div className="border-bottom py-3 px-3 d-sm-flex align-items-center">
				<div className="custom-style component-wrapper mx-2" id="from">
					<jb-date-input format="YYYY-MM-DD" placeholder={langs.startTimeRequest} input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} ></jb-date-input>
				</div>
				<div className="custom-style component-wrapper mx-2" id="to">
					<jb-date-input format="YYYY-MM-DD" input-type={localStorage.lang == "fa" ? "JALALI" : "GREGORIAN"} placeholder={langs.endTimeRequest}></jb-date-input>
				</div>
					{ config?.statuses?.length > 0 ? 
						<>
							<div className="" style={{margin: "0 8px"}}>
								<select onChange={(e) => { 
									var json = option;
									json.status_id = Number(e.target.value) 
									json.page = 1;
									setOptions(json);
									get();
								}} className="form-control " style={{fontSize: "13px", fontWeight: 500 }} >
									<option value="">{langs.status}: {langs.allItems}</option>
									{config.statuses.map((status, index) => (
										<option key={index} value={status.id}>{langs.status}: {status.label}</option>
									))}
								</select>
							</div>
						</> : null
					}
				
					{ user.role == "admin"  && config?.operators?.length > 0 ? 
						<>
							<div className="" style={{margin: "0 8px"}}>
								<select onChange={(e) => { 
									var json = option;
									json.operator_id = Number(e.target.value) 
									json.page = 1;
									setOptions(json);
									get();
								}} className="form-control " style={{fontSize: "13px", fontWeight: 500 }} >
									<option value="">{langs.operator}: {langs.allItems}</option>
									{config.operators.map((operator, index) => (
										<option key={index} value={operator.id}>{langs.operator}: {operator.username}</option>
									))}
								</select>
							</div>
						</> : null
					}
				

              </div>
			  </> : <></> }

                <div className="table-responsive p-0">
                  <table className="table align-items-center mb-0">
                    <thead className="bg-gray-100">
                      <tr>
						{ window.location.pathname != "/orders" ? <>
							<th className="text-secondary opacity-7"></th>
						</> : <></> }
                        <th className="text-secondary text-xs font-weight-semibold opacity-7">{langs._id}</th>
                        <th className="text-secondary text-xs font-weight-semibold opacity-7">{langs.product}</th>
						{ window.location.pathname != "/orders" ?

                        <><th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.firstName} {langs.lastName}</th></>
							: <></>
						}
                        <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.price}</th>
                        <><th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.report_order}</th></>
                        <><th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.reserveTime}</th></>
                        <><th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">{langs.rentDuration}</th></>

                        <th className="text-center text-secondary text-xs font-weight-semibold opacity-7">{langs.status}</th>
							{ 
							//<th className="text-center text-secondary text-xs font-weight-semibold opacity-7">{langs.time}</th>
							}
						{ window.location.pathname != "/orders" ? <>
							<th className="text-secondary opacity-7"></th>
						</> : <></> }
                      </tr>
                    </thead>
                    <tbody>
                      {orders.data.map((order, index) => (

                        <tr key={index}>
						{ window.location.pathname != "/orders" ?
							<>					
                          <td className="align-middle text-center">
                            <a href={`/dashboard/orders/${order.id}/edit`} className="text-secondary font-weight-bold text-xs" data-bs-toggle="tooltip" data-bs-title="Edit user">
                              <svg width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.2201 2.02495C10.8292 1.63482 10.196 1.63545 9.80585 2.02636C9.41572 2.41727 9.41635 3.05044 9.80726 3.44057L11.2201 2.02495ZM12.5572 6.18502C12.9481 6.57516 13.5813 6.57453 13.9714 6.18362C14.3615 5.79271 14.3609 5.15954 13.97 4.7694L12.5572 6.18502ZM11.6803 1.56839L12.3867 2.2762L12.3867 2.27619L11.6803 1.56839ZM14.4302 4.31284L15.1367 5.02065L15.1367 5.02064L14.4302 4.31284ZM3.72198 15V16C3.98686 16 4.24091 15.8949 4.42839 15.7078L3.72198 15ZM0.999756 15H-0.000244141C-0.000244141 15.5523 0.447471 16 0.999756 16L0.999756 15ZM0.999756 12.2279L0.293346 11.5201C0.105383 11.7077 -0.000244141 11.9624 -0.000244141 12.2279H0.999756ZM9.80726 3.44057L12.5572 6.18502L13.97 4.7694L11.2201 2.02495L9.80726 3.44057ZM12.3867 2.27619C12.7557 1.90794 13.3549 1.90794 13.7238 2.27619L15.1367 0.860593C13.9869 -0.286864 12.1236 -0.286864 10.9739 0.860593L12.3867 2.27619ZM13.7238 2.27619C14.0917 2.64337 14.0917 3.23787 13.7238 3.60504L15.1367 5.02064C16.2875 3.8721 16.2875 2.00913 15.1367 0.860593L13.7238 2.27619ZM13.7238 3.60504L3.01557 14.2922L4.42839 15.7078L15.1367 5.02065L13.7238 3.60504ZM3.72198 14H0.999756V16H3.72198V14ZM1.99976 15V12.2279H-0.000244141V15H1.99976ZM1.70617 12.9357L12.3867 2.2762L10.9739 0.86059L0.293346 11.5201L1.70617 12.9357Z" fill="#64748B" />
                              </svg>
                            </a>
                          </td>
						  </>
						  : <></>

						  }
                          <td className="align-middle text-center text-sm">
                            <p className="text-sm text-secondary mb-0">{order.id}</p>
                          </td>
                          <td>
                            <div className="d-flex px-2 py-1">
							{order?.product?.media?.length > 0 ? 
								<>
								  <div className="d-flex align-items-center">
									<img src={order.product.media[0].url} className="avatar avatar-sm rounded-circle me-2" alt="user1" />
								  </div>
								</>
							: null} 
                              <div className="d-flex flex-column justify-content-center ms-1">
                                <h6 className="mb-0 text-sm font-weight-semibold">{order?.product?.title}</h6>
                                <p className="text-sm text-secondary mb-0">{order?.product?.category[0].title}</p>
                                <p className="text-sm text-secondary mb-0">
									{order?.product?.user?.information?.name}
									<small style={{ margin: 5}}>({order?.product?.user?.information?.nick_name})</small>
								</p>
                              </div>
                            </div>
                          </td>
						{ window.location.pathname != "/orders" ? <>
                          <td className="align-middle text-center text-sm">
                            <div className="  px-2 py-1">

							{order?.user?.information ? 
								<>
									<p className="text-sm text-dark font-weight-semibold mb-0">{order?.user.information.nick_name}</p>
									<p className="text-sm text-dark font-weight-semibold mb-0">{order?.user.information.name}</p>
									<p className="text-sm text-secondary mb-0">{order?.user.information.id_card}</p>
									{ user.role == "admin" ? <>
									
									<a style={{margin : 0}}  href={`https://wa.me/${order?.user.information.phone_number}`} target="_blank" type="button" className="btn btn-sm btn-dark btn-icon me-2">
										<span className="btn-inner--text">{langs.customerSupport}</span>
									</a>
									</> : <></> }
								</>
							: null} 

                          </div>

                          </td>
						</> : <></> }

                          <td className="align-middle text-center text-sm">
							{order.product?.price?.length > 0 ? 
								<>
									<p className="text-sm text-secondary mb-0">{
										parseFloat(order?.order_price)?.toFixed(3)
									}</p>
									{
										(order?.product?.user) && (
											user.role == "admin" || user.role == "operator"
										) && order?.price?.id
									? <>
										<p className="text-sm text-secondary mb-0">
										<strong> {langs.provider}: </strong>
										{
											parseFloat(
												( order?.order_price * order?.product?.user[`${order?.price?.type}_percent`] ) / 100
											)?.toFixed(3)
										}</p>
										<p className="text-sm text-secondary mb-0">
										<strong> {langs.admin}: </strong>
										{
											parseFloat(
												( order?.order_price * ( 100 - order?.product?.user[`${order?.price?.type}_percent`] ) ) / 100
											)?.toFixed(3)
										}</p>
									</> : <></>}
								</>
							: null} 

                          </td>
 
                          <td className="align-middle text-left text-sm">
							<p className="details-text"><b>{langs[order.place]} </b> 
							{order.details}
							<br/>
							{ user.role == "operator" ? <>
							<a style={{marginTop: 10}}  href={`https://wa.me/${order?.user?.information?.phone_number}`} target="_blank" type="button" className="btn btn-sm btn-dark btn-icon me-2">
							  <span className="btn-inner--text">{langs.customerSupport}</span>
							</a>
							</> : <>
							<a style={{marginTop: 10}}  href={`https://wa.me/${order?.product?.user?.information?.phone_number}`} target="_blank" type="button" className="btn btn-sm btn-dark btn-icon me-2">
							  <span className="btn-inner--text">{langs.rentalSupport}</span>
							</a>
							</> }
							
							</p>
						  {/*
							( order?.status?.name == "finish" || order?.status?.name == "payed"  ) ?
								<>
									<p className="text-sm text-dark font-weight-semibold mb-0">{
										parseFloat(
										Math.round(
										calPrice(new Date(order?.finished_at), new Date(order?.accepted_at), order?.order_price)
										)).toFixed(3)
									} 
									</p>
									<p className="text-sm text-secondary mb-0">{langs.symbol}</p>
								</>
							: <>-</>
						*/}
                          </td>

						  <td className="align-middle text-center text-sm">
						 {
							  ( order?.created_time  ) ?
								<>
								{ order.created_time }
								</>
							: <>-</>
						 }
						  </td>
                          <td className="align-middle text-center text-sm">
						  {
							  ( order?.status?.name == "finish" || order?.status?.name == "payed"  ) ?
								<>
								{ 
									//fromTo(new Date(order?.finished_at), new Date(order?.accepted_at)) 
								}
								</>
							: <></>
							  
						  }	
						  <p style={{ margin: 0, fontSize: 10 }}>
						{  order.from }
						<br/>
						{ order.to }
						</p>

                          </td>

                          <td className="align-middle text-center text-sm">
                            <span className={`badge badge-sm border border-success text-success bg-success`}>{order?.status?.label}</span>
                          </td>
						  {
							  /*
                          <td className="align-middle text-center">
                            <span className="text-secondary text-sm font-weight-normal">{order.updated_time}</span>
                          </td>
						  */
						  }
  	  					{ window.location.pathname != "/orders" ?
							<>					
                          <td className="align-middle">
                            <a href={`/dashboard/orders/${order.id}/edit`} className="text-secondary font-weight-bold text-xs change-money top-0" data-bs-toggle="tooltip" data-bs-title="Edit user">
                              <svg width="14" height="14" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.2201 2.02495C10.8292 1.63482 10.196 1.63545 9.80585 2.02636C9.41572 2.41727 9.41635 3.05044 9.80726 3.44057L11.2201 2.02495ZM12.5572 6.18502C12.9481 6.57516 13.5813 6.57453 13.9714 6.18362C14.3615 5.79271 14.3609 5.15954 13.97 4.7694L12.5572 6.18502ZM11.6803 1.56839L12.3867 2.2762L12.3867 2.27619L11.6803 1.56839ZM14.4302 4.31284L15.1367 5.02065L15.1367 5.02064L14.4302 4.31284ZM3.72198 15V16C3.98686 16 4.24091 15.8949 4.42839 15.7078L3.72198 15ZM0.999756 15H-0.000244141C-0.000244141 15.5523 0.447471 16 0.999756 16L0.999756 15ZM0.999756 12.2279L0.293346 11.5201C0.105383 11.7077 -0.000244141 11.9624 -0.000244141 12.2279H0.999756ZM9.80726 3.44057L12.5572 6.18502L13.97 4.7694L11.2201 2.02495L9.80726 3.44057ZM12.3867 2.27619C12.7557 1.90794 13.3549 1.90794 13.7238 2.27619L15.1367 0.860593C13.9869 -0.286864 12.1236 -0.286864 10.9739 0.860593L12.3867 2.27619ZM13.7238 2.27619C14.0917 2.64337 14.0917 3.23787 13.7238 3.60504L15.1367 5.02064C16.2875 3.8721 16.2875 2.00913 15.1367 0.860593L13.7238 2.27619ZM13.7238 3.60504L3.01557 14.2922L4.42839 15.7078L15.1367 5.02065L13.7238 3.60504ZM3.72198 14H0.999756V16H3.72198V14ZM1.99976 15V12.2279H-0.000244141V15H1.99976ZM1.70617 12.9357L12.3867 2.2762L10.9739 0.86059L0.293346 11.5201L1.70617 12.9357Z" fill="#64748B" />
                              </svg>
                            </a>
							
                            <a className="change-money" onClick={()=>{
								var amount = prompt(langs.price, order.order_price);
								if(amount && Number(amount) != Number(order.order_price) ){
									http.post(`/orders/${order.id}/edit/price`, { price: Number(amount) }).then(response => {
										if (response.data) {
											toast.success(response.data.message);
											get();
										}
									})
									.catch(err => {
										toast.error(err.message);
									})
								}
							}}>
								<i className="fa fa-money"></i>
                            </a>
							
							
								<a className="change-money" onClick={()=>{
									http.post(`/orders/${order.id}/changeStatus`, { status_id: -1 }).then(response => {
										if (response.data) {
											toast.success(response.data.message);
											get();
										}
									})
									.catch(err => {
										toast.error(err.message);
									})
								}}>
									<i className="fa fa-trash"></i>
								</a>
							
							{ order.status_id == 15 ? <></> : <>
								<a className="change-money" onClick={()=>{
									http.post(`/orders/${order.id}/changeStatus`, { status_id: 15 }).then(response => {
										if (response.data) {
											toast.success(response.data.message);
											get();
										}
									})
									.catch(err => {
										toast.error(err.message);
									})
								}}>
									<i className="fa fa-archive"></i>
								</a>
							</> }

							{ order.status_id == 13 ? <></> : <>
								<a className="change-money" onClick={()=>{
									http.post(`/orders/${order.id}/changeStatus`, { status_id: 13 }).then(response => {
										if (response.data) {
											toast.success(response.data.message);
											get();
										}
									})
									.catch(err => {
										toast.error(err.message);
									})
								}}>
									<i className="fa fa-thumbs-up"></i>
								</a>
							</> }

                          </td>
						  </>
						  : <></>

						  }
                        </tr>

                      ))}

                    </tbody>
                  </table>
                </div>

                <div className="border-top py-3 px-3 d-flex align-items-center">
                  <p className="font-weight-semibold mb-0 text-dark text-sm">{langs.page} {option.page}</p>
                  <div className="ms-auto">
                    {orders.next_page_url ? <button className="btn btn-sm btn-white mb-0"
						onClick={(e) => { 
							var json = option;
							json.page = option.page + 1;
							setOptions(json);
							get();
						}}
					>{langs.next_page}</button> : null}
                    {orders.prev_page_url ? <button className="btn btn-sm btn-white mb-0"
					
						onClick={(e) => { 
							var json = option;
							json.page = option.page - 1;
							setOptions(json);
							get();
						}}
					>{langs.previous_page}</button> : null}
                  </div>
                </div>
				
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>




  );
}

const mapStateToProps = (state) => ({
  user: state.auth.currentUser
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER })
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);