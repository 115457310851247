import axios from "axios";
import store from "../store";
import ActionTypes from "../store/action-types";
import Config from "../config/app";

if (!localStorage.lang) localStorage.setItem("lang", Config.LANG);
if (!localStorage.theme) localStorage.setItem("theme", Config.THEME);


const lang_id = localStorage.lang;



const all = {
	fa: 
	{
		"csv_export": "خروجی اکسل",
		"clearing": "تسویه",
		"clearing_now": "تسویه تاکنون",
		"save": "ذخیره",
		"nick_name": "نام مستعار",
		"support": "پشتیبانی",
		"provider": "فراهم کننده",
		"dashboard": "صفحه نخست",
		"product": "خودرو",
		"products": "خودرو ها",
		"users": "کاربران",
		"orders": "سفارشات",
		"ordersNotic": "در این قسمت شما می توانید سفارش ها را مشاهده نمایید",
		"profile": "پروفایل",
		"profileNotic": "جهت استفاده از رنتینو رنت پروفایل خود را تکمیل نمایید",
		"profileComplete": "تکمیل پروفایل",
		"aboutUs": "درباره ما",
		"blog": "وبلاگ",
		"copyRight": "تمومی حقوق مادی و معنوی متعلق به رنتینو می باشد",
		"login": "ورود",
		"register": "ثبت نام",
		"logout": "خروج",
		"myOrders": "سفارش های من",
		"welcome": "خوش آمدید",
		"welcomeNotic": "رنتینو سامانه اجاره خودرو",
		"username": "ایمیل",
		"password": "رمز عبور",
		"registerNotic": "حساب کاربری دارم",
		"dear": "عزیز",
		"sendCode": "ارسال کد",
		"sendAgain": "ارسال مجدد",
		"checkCode": "بررسی کد",
		"code": "کد",
		"seconds": "ثانیه",
		"completeProfile": "تکمیل اطلاعات",
		"idCard": "کد ملی",
		"firstName": "نام",
		"first_name": "نام",
		"lastName": "نام خانوادگی",
		"last_name": "نام خانوادگی",
		"id_card": "کارت ملی",
		"id_card_path": "عکس کارت ملی",
		"id_card_back_path": "عکس گواهینامه",
		"createProduct": "ایجاد محصول",
		"createProductNotic": "در این قسمت شما می توانید محصول خود را وارد نمایید",
		"createUser": "ایجاد کاربر",
		"createUsersNotic": "در این قسمت شما می توانید کاربر خود را وارد نمایید",
		"addImage": "افزودن تصاویر",
		"title": "عنوان",
		"body": "توضیحات",
		"status": "وضعیت",
		"statusNotic": "در این قسمت شما می توانید وضعیت خود را وارد نمایید",
		"price": "قیمت",
		"priceNotic": "در این قسمت شما می توانید قیمت خود را وارد نمایید",
		"category": "دسته بندی",
		"categoryNotic":  "در این قسمت شما می توانید دسته بندی خود را وارد نمایید",
		"create":  "ایجاد",
		"update":  "ویرایش",
		"updateUser":  "ویرایش کاربر",
		"updateUserNotic":  "در این قسمت می توانید اطلاعات کاربری را ویرایش نمایید",
		"updateProduct":  "ویرایش محصول",
		"updateOrder":  "ویرایش سفارش",
		"updateProductNotic":  "در این قسمت می توانید محصول خود را ویرایش نمایید",
		"updateOrderNotic":  "در این قسمت می توانید سفارش را ویرایش نمایید",
		"_id":  "شناسه",
		"revenue":  "درآمد",
		"page":  "صفحه",
		"of":  "از",
		"next_page":  "صفحه بعد",
		"previous_page":  "صفحه قبل",
		"created_at":  "زمان ایجاد",
		"hour":  "ساعتی",
		"day":  "روزانه",
		"week":  "هفتگی",
		"month":  "ماهانه",
		"information":  "اطلاعات",
		"name":  "نام",
		"reserved_successfully":  "با موفقیت رزرو شد",
		"done_successfully":  "با موفقیت انجام شد",
		"time":  "زمان",
		"details":  "جزییات",
		"base_price":  "قیمت پایه",
		"add_detail":  "افزودن پلاک و جزییات",
		"accept":  "تایید",
		"reject":  "عدم تایید",
		"finish":  "اتمام",
		"role":  "نقش",
		"customer":  "مشتری",
		"admin":  "ادمین",
		"operator":  "اپراتور",
		"order":  "سفارش",
		"symbol":  "OMR",
		"payed":  "پرداخت شد",
		"finalPrice":  "قیمت نهایی",
		"rentDuration":  "مدت زمان اجاره",
		"dayLabel"	: "روز",
		"monthLabel"	: "ماه",
		"weekLabel"	: "هفته",
		"hourLabel"	: "ساعت",
		"minLabel"	: "دقیقه",
		"secLabel"	: "ثانیه",
		"addad"	: "عدد",
		"reserve"	: "رزرو کن!",
		"rentTime" : "زمان اجاره",
		"startTimeRequest" : "زمان شروع درخواست",
		"endTimeRequest" : "زمان اتمام درخواست",
		"allItems" : "همه موارد",
		"populars" : "محبوب ترین ها",
		"newest" : "جدید ترین ها",
		"see_all" : "مشاهده همه",
		"policy" : "قوانین و مقررات",
		"contactus": "تماس با ما",
		"aboutus": "درباره ما",
		"search" : "جستجو کنید...",
		"reports" : "گزارشات",
		"acceptPolicy":  "با عضویت در رنتینو، شرابط و قوانین را می پذیرم",
		"shoar" : "اجاره خودرو  رنتینو، همیشه در کنار شما",
		"continuation" : "ادامه",
		"loginRegister" : "ورود یا ثبت نام",
		"st1" : "خوش آمدید",
		"sb1" : "سرویس اجاره خودرو",
		"st2" : "صرفه جویی در زمان",
		"sb2" : "انتخاب سریع و تحویل به موقع خودرو درخواستی",
		"st3" : "ارزان و خوش قیمت",
		"sb3" : "به صرفه ترین و بهترین قیمت اجاره خودرو",
		"ays": "آیا مطمئنی؟",
		"hotel": "هتل",
		"airport": "فرودگاه",
		"dep_type": "نحوه پرداخت",
		"cash": "نقدی",
		"rsv_time": "برای چه روزی می خواهید رزرو کنید",
		"end_time": "تا چه روزی می خواهید رزرو کنید",
		"ch_time": "زمان را انتخاب کنید",
		"pick_type": "در چه مکانی می خواهید تحویل بگیرید",
		"addr_type": "آدرس مکانی که می خواهید خودرو را تحویل بگیرید را وارد نمایید ",
		"rent_period": "دوره اجاره",
		"report_order": "گزارش سفارش",
		"phone_number": "شماره موبایل",
		"plate": "شماره",
		"percent": "درصد",
		"operator": "تامین کننده",
		"close": "بستن",
		"send_request": "ارسال درخواست",
		"shop": "مغازه",
		"archive": "آرشیو",
		"customerSupport": "تماس با مشتری",
		"rentalSupport": "تماس با اجاره دهنده",
		"deleteAccount": "حذف حساب",
	},
	ar: 
	{
		"csv_export": "تصدير اکسل",
		"clearing": "المقاصة",
		"clearing_now": "التسوية حتى الآن",
		"save": "يحفظ",
		"nick_name": "كنية",
		"provider": "مزود",
		"dashboard": "الصفحة الرئيسية",
		"product": "المنتج",
		"products": "سيارات",
		"users": "المستخدمين",
		"orders": "طلبات",
		"ordersNotic": "في هذا القسم يمكنك رؤية الطلبات",
		"profile": "حساب تعريفي",
		"profileNotic": "لاستخدام شاهين للإيجار، أكمل ملفك الشخصي",
		"profileComplete": "أكمل الملف الشخصي",
		"aboutUs": "معلومات عنا",
		"blog": "مدونة",
		"copyRight": "جميع الحقوق المادية والفكرية مملوكة لشاهين",
		"login": "تسجيل الدخول",
		"register": "يسجل",
		"logout": "مخرج",
		"myOrders": "طلباتي",
		"welcome": "مرحباً",
		"welcomeNotic": "نظام شاهين لتأجير السيارات",
		"username": "بريد إلكتروني",
		"password": "كلمة المرور",
		"registerNotic": "لدي حساب",
		"dear": "عزيزي",
		"sendCode": "إرسال الرمز",
		"sendAgain": "إعادة إرسال",
		"checkCode": "تحقق من الرمز",
		"code": "شفرة",
		"seconds": "ثانية",
		"completeProfile": "استكمال المعلومات",
		"idCard": "رمز دولي",
		"firstName": "اسم",
		"first_name": "اسم",
		"lastName": "اسم العائلة",
		"last_name": "اسم العائلة",
		"id_card": "جواز سفر",
		"id_card_path": "صورة جواز السفر",
		"id_card_back_path": "صورة الشهادة",
		"createProduct": "إنشاء المنتج",
		"createProductNotic": "في هذا القسم يمكنك إدخال المنتج الخاص بك",
		"createUser": "إنشاء مستخدم",
		"createUsersNotic": "في هذا القسم يمكنك إدخال المستخدم الخاص بك",
		"addImage": "إضافة الصور",
		"title": "عنوان",
		"body": "وصف",
		"status": "حالة",
		"statusNotic": "في هذا القسم يمكنك إدخال حالتك",
		"price": "سعر",
		"priceNotic": "في هذا القسم يمكنك إدخال السعر الخاص بك",
		"category": "التجميع",
		"categoryNotic": "في هذا القسم يمكنك إدخال فئتك",
		"create": "خلق",
		"update": "يحرر",
		"updateUser": "تحرير العضو",
		"updateUserNotic": "في هذا القسم، يمكنك تحرير معلومات المستخدم",
		"updateProduct": "تحرير المنتج",
		"updateOrder": "تحرير الطلب",
		"updateProductNotic": "في هذا القسم يمكنك تعديل منتجك",
		"updateOrderNotic": "في هذا القسم يمكنك تعديل الطلب",
		"_id": "بطاقة تعريف",
		"revenue": "دخل",
		"page": "صفحة",
		"of": "من",
		"next_page": "الصفحة التالية",
		"previous_page": "الصفحة السابقة",
		"created_at": "وقت الابتكار",
		"hour": "ساعة",
		"day": "يوميًا",
		"week": "أسبوعي",
		"month": "شهريا",
		"information": "معلومة",
		"name": "اسم",
		"reserved_successfully": "تم الحجز بنجاح",
		"done_successfully": "فعلت بنجاح",
		"time": "وقت",
		"details": "تفاصيل",
		"base_price": "السعر الأساسي",
		"add_detail": "إضافة لوحة الترخيص والتفاصيل",
		"accept": "تأكيد",
		"reject": "الرفض",
		"finish": "نهاية",
		"role": "دور",
		"customer": "عميل",
		"admin": "مسؤل",
		"operator": "المشغل أو العامل",
		"order": "طلب",
		"symbol": "ریال",
		"payed": "تم دفعه",
		"finalPrice": "السعر النهائي",
		"rentDuration": "مدة الإيجار",
		"dayLabel": "يوم",
		"monthLabel": "شهر",
		"weekLabel": "أسبوع",
		"hourLabel": "الساعة",
		"minLabel": "دقائق",
		"secLabel": "ثانية",
		"addad": "رقم",
		"reserve": "احجز!",
		"rentTime": "وقت الإيجار",
		"startTimeRequest": "وقت بدء التطبيق",
		"endTimeRequest": "طلب وقت الانتهاء",
		"allItems": "جميع المواد",
		"populars": "المفضلة",
		"newest": "الأحدث",
		"see_all": "عرض الكل",
		"policy": "الأحكام والشروط",
		"contactus": "اتصل بنا",
		"aboutus": "نبذة عنا",
		"search": "يبحث...",
		"reports": "التقارير",
		"acceptPolicy": "عندما أصبح عضوا في شاهين، فإنني أقبل القواعد واللوائح",
		"shoar": "شاهين لتأجير السيارات، دائما بجانبك",
		"continuation": "استمرار",
		"loginRegister": "تسجيل الدخول أو التسجيل",
		"st1": "مرحباً",
		"sb1": "خدمة تأجير السيارات",
		"st2": "توفير الوقت",
		"sb2": "الاختيار السريع والتسليم في الوقت المناسب للسيارة المطلوبة",
		"st3": "سعر رخيص وجيد",
		"sb3": "الأكثر اقتصادا وأفضل سعر لتأجير السيارات",
		"ays": "هل أنت متأكد؟",
		"hotel": "الفندق",
		"airport": "مطار",
		"dep_type": "طريقة الدفع او السداد",
		"cash": "نقدي",
		"rsv_time": "في أي يوم تريد الحجز؟",
		"end_time": "لكي أرغب في الحصول على رزرو اليوم",
		"ch_time": "اختر الوقت",
		"pick_type": "أين تريد أن تلتقط؟",
		"addr_type": "لا يلزمك العنوان المكاني الذي ترغب في تجربة ركوبه",
		"close": "يغلق",
		"send_request": "ارسل طلب",
		"pickup_time": "اختار المعاد",
		"pickup_place": "مكان الالتقاط",
		"support": "يدعم",
		"rent_period": "فترة الإيجار",
		"report_order": "ترتيب التقرير",
		"phone_number": "رقم الهاتف",
		"plate": "لوحة رقم",
		"percent": "النسبة المئوية",
		"operator": "مزود",
		"shop": "محل",
		"archive": "أرشيف",
		"customerSupport": "الاتصال بالعملاء",
		"rentalSupport": "اتصل بالمؤجر",
		"deleteAccount": "حذف الحساب",
	},
	en: 
	{
		"csv_export": "Export CSV",
		"clearing": "Clearing",
		"clearing_now": "Settlement so far",
		"save": "save",
		"nick_name": "nick name",
		"provider": "Provider",
		"dashboard": "Home Page",
		"product": "the product",
		"products": "Cars",
		"users": "users",
		"orders": "orders",
		"ordersNotic": "In this section you can see the orders",
		"profile": "profile",
		"profileNotic": "To use rentino Rent, complete your profile",
		"profileComplete": "Complete the profile",
		"aboutUs": "about us",
		"blog": "weblog",
		"copyRight": "All material and intellectual rights belong to rentino",
		"login": "log in",
		"register": "Register",
		"logout": "Exit",
		"myOrders": "My orders",
		"welcome": "welcome",
		"welcomeNotic": "rentino car rental system",
		"username": "email",
		"password": "password",
		"registerNotic": "I have an account",
		"dear": "Dear",
		"sendCode": "Send code",
		"sendAgain": "resend",
		"checkCode": "Check the code",
		"code": "code",
		"seconds": "Second",
		"completeProfile": "Completion of information",
		"idCard": "National Code",
		"firstName": "first name",
		"first_name": "first name",
		"lastName": "last name",
		"last_name": "last name",
		"id_card": "Passport / ID card",
		"id_card_path": "Passport / ID card photo",
		"id_card_back_path": "License photo",
		"createProduct": "Product creation",
		"createProductNotic": "In this section you can enter your product",
		"createUser": "Create a user",
		"createUsersNotic": "In this section you can enter your user",
		"addImage": "Add images",
		"title": "Title",
		"body": "Description",
		"status": "Condition",
		"statusNotic": "In this section you can enter your status",
		"price": "Price",
		"priceNotic": "In this section you can enter your price",
		"category": "Grouping",
		"categoryNotic": "In this section you can enter your category",
		"create": "Creation",
		"update": "Edit",
		"updateUser": "Edit user",
		"updateUserNotic": "In this section, you can edit user information",
		"updateProduct": "Product editing",
		"updateOrder": "Edit order",
		"updateProductNotic": "In this section you can edit your product",
		"updateOrderNotic": "In this section you can edit the order",
		"_id": "ID",
		"revenue": "Income",
		"page": "Page",
		"of": "From",
		"next_page": "next page",
		"previous_page": "previous page",
		"created_at": "Creation time",
		"hour": "an hour",
		"day": "Daily",
		"week": "weekly",
		"month": "monthly",
		"information": "Information",
		"name": "name",
		"reserved_successfully": "Booked successfully",
		"done_successfully": "done successfully",
		"time": "Time",
		"details": "details",
		"base_price": "base price",
		"add_detail": "Add license plate and details",
		"accept": "confirmation",
		"reject": "disapproval",
		"finish": "end",
		"role": "Role",
		"customer": "Customer",
		"admin": "Admin",
		"operator": "Provider",
		"order": "Order",
		"symbol": "OMR",
		"payed": "was paid",
		"finalPrice": "The final price",
		"rentDuration": "Rental period",
		"dayLabel": "Day",
		"monthLabel": "Month",
		"weekLabel": "week",
		"hourLabel": "the watch",
		"minLabel": "minutes",
		"secLabel": "Second",
		"addad": "number",
		"reserve": "Reserve",
		"rentTime": "Rental time",
		"reserveTime": "reserve time",
		"startTimeRequest": "Application start time",
		"endTimeRequest": "Request completion time",
		"allItems": "all items",
		"populars": "favorites",
		"newest": "newest",
		"see_all": "view all",
		"policy": "Terms and Conditions",
		"contactus": "Contact Us",
		"aboutus": "About Us",
		"search": "search...",
		"reports": "reports",
		"acceptPolicy": "By becoming a member of rentino, I accept the rules and regulations",
		"shoar": "rentino car rental, always by your side",
		"continuation": "Continuation",
		"loginRegister": "Login or register",
		"st1": "welcome",
		"sb1": "Car rental service",
		"st2": "Saving time",
		"sb2": "Quick selection and timely delivery of the requested car",
		"st3": "Cheap and good price",
		"sb3": "The most economical and best car rental price",
		"ays": "are you sure?",
		"hotel": "hotel",
		"airport": "airport",
		"dep_type": "payment method",
		"cash": "cash",
		"rsv_time": "What day do you want to book?",
		"end_time": "To want to have book today?",
		"ch_time": "Choose Time",
		"pick_type": "Where do you want to pick up?",
		"addr_type": "Find out where I am located and then change it again.",
		"close": "Close",
		"send_request": "Send Request",
		"pickup_time": "taking car",
		"pickup_place": "pickup place",
		"support": "Support",
		"rent_period": "Rent Period",
		"percent": "percent",
		"operator": "Provider",
		"report_order": "report order",
		"phone_number": "phone number",
		"plate": "plate no",
		"shop": "shop",
		"archive": "archive",
		"customerSupport": "Customer Support",
		"rentalSupport": "Rental Support",
		"deleteAccount": "Delete Account",

	}
}

export let langs = all[lang_id];
