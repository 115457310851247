import './assets/css/corporate-ui-dashboard.css';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './App.css';

import './assets/fontiran.css';
import './assets/arabifan.css';

import { Router, Switch } from 'react-router-dom';
import { history } from './helpers';
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SignUpPage from './pages/registerPage';
import AuthPage from './pages/authPage';
import LoginPage from './pages/loginPage';
import Home from './pages/home';
import Product from './pages/product';
import Products from './pages/products';
import About from './pages/about';
import Policy from './pages/policy';

import AdminProducts from './pages/admin/products/index';
import AdminProductCreate from './pages/admin/products/create';
import AdminProductEdit from './pages/admin/products/edit';

import AdminUsers from './pages/admin/users/index';
import AdminUserCreate from './pages/admin/users/create';
import AdminUserEdit from './pages/admin/users/edit';

import AdminOrders from './pages/admin/orders/index';
import AdminOrderEdit from './pages/admin/orders/edit';

const App = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
		{
			/*
          <PublicRoute exact path='/login' component={LoginPage} />
          <PublicRoute exact path='/register' component={SignUpPage} />
		  */
		}
          <PublicRoute exact path='/login' component={AuthPage} />
          <PublicRoute exact path='/' component={Products} />
          <PrivateRoute exact path='/dashboard/products' component={AdminProducts} />
          <PrivateRoute exact path='/dashboard/products/create' component={AdminProductCreate} />
          <PrivateRoute exact path='/dashboard/products/:id/edit' component={AdminProductEdit} />
		  
          <PublicRoute exact path='/about' component={About} />
          <PublicRoute exact path='/policy' component={Policy} />
		  
          <PrivateRoute exact path='/dashboard/users' component={AdminUsers} />
          <PrivateRoute exact path='/dashboard/users/create' component={AdminUserCreate} />
          <PrivateRoute exact path='/dashboard/users/:id/edit' component={AdminUserEdit} />
		  
          <PrivateRoute exact path='/dashboard/orders' component={AdminOrders} />
          <PrivateRoute exact path='/dashboard/reports' component={AdminOrders} />
          <PrivateRoute exact path='/dashboard/orders/:id/edit' component={AdminOrderEdit} />
		  
          <PublicRoute exact path='/products' component={Products}/>
          <PrivateRoute exact path='/orders' component={AdminOrders} me={true}/>
          <PrivateRoute exact path='/profile' component={AdminUserEdit} me={true}/>
          <PublicRoute exact path='/product/:id' component={Product}/>
        </Switch>
      </Router>
      <ToastContainer 
		position="bottom-center"
		autoClose={3000} />
    </>
  );
}

export default App;
